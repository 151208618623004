$(document).ready(function () {
    $('header .nav-button').unbind().click(function () {
        $('#nav_wrapper nav').toggleClass('toggle');
    });

    $(window).scroll(function () {
        if ($(this).scrollTop() > 0) {
            $('header').addClass('fixed');
            $('.icon-select').addClass('top-icon');
        } else {
            $('header').removeClass('fixed');
            $('.icon-select').removeClass('top-icon');
        }
    });

    initCarousel();
    initVideoFrame();
    startReviewCarousel();
});


function initCarousel() {
    $('#carouselContent').slick({
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        prevArrow: $('.slick-prev'),
        nextArrow: $('.slick-next')
    });
}

function startReviewCarousel() {
    $('#reviewWrapper').slick({
        dots: false,
        speed: 300,
        infinite: true,
        slidesToScroll: 1,
        slidesToShow: 1,
        buttons: false,
        autoplay: true,
        autoplaySpeed: 2000,
        fade: true,
        cssEase: 'linear',
        prevArrow: $('#rev-prev'),
        nextArrow: $('#rev-next')
    });
}

function initVideoFrame() {
    $("#videoblock").magnificPopup({
        type: 'iframe',
        disableOn: function () { // don't use a popup for mobile
            if ($(window).width() < 600) {
                return false;
            }
            return true;
        },

        iframe: {
            markup: '<div class="mfp-iframe-scaler">' +
                '<div class="mfp-close"></div>' +
                '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
                '</div>', // HTML markup of popup, `mfp-close` will be replaced by the close button

            patterns: {
                youtube: {
                    index: 'youtube.com/', // String that detects type of video (in this case YouTube). Simply via url.indexOf(index).

                    id: 'v=', // String that splits URL in a two parts, second part should be %id%
                    // Or null - full URL will be returned
                    // Or a function that should return %id%, for example:
                    // id: function(url) { return 'parsed id'; }

                    src: '//www.youtube.com/embed/%id%?autoplay=1' // URL that will be set as a source for iframe.
                },
                vimeo: {
                    index: 'vimeo.com/',
                    id: '/',
                    src: '//player.vimeo.com/video/%id%?autoplay=1'
                },
                gmaps: {
                    index: '//maps.google.',
                    src: '%id%&output=embed'
                }

                // you may add here more sources

            },

            srcAction: 'iframe_src', // Templating object key. First part defines CSS selector, second attribute. "iframe_src" means: find "iframe" and set attribute "src".
        }
    });
}

document.addEventListener("DOMContentLoaded", function() {
    var lazyImages = [].slice.call(document.querySelectorAll("img.lazy"));

    if ("IntersectionObserver" in window) {
        let lazyImageObserver = new IntersectionObserver(function(entries, observer) {
            entries.forEach(function(entry) {
                if (entry.isIntersecting) {
                    let lazyImage = entry.target;
                    lazyImage.src = lazyImage.dataset.src;
                    lazyImage.classList.remove("lazy");
                    lazyImageObserver.unobserve(lazyImage);
                }
            });
        });

        lazyImages.forEach(function(lazyImage) {
            lazyImageObserver.observe(lazyImage);
        });
    } else {
        // Possibly fall back to a more compatible method here
    }
});



